<template>
  <div class="home">
    <div class="nav">
      <div class="header">
        <div class="wallet-box">
          <span>{{ walletAddress ? strUtils.sliceString(walletAddress) : 'Link Wallet' }}</span>
        </div>
      </div>
    </div>
    <div class="main">
      <el-card class="search-box">
        <template #header>订单查询-可提收益({{ totalIncome.toFixed(2) }})</template>
        <el-form :inline="true" class="form-inline">
          <el-form-item label="钱包地址：">
            <el-input v-model="customerAddress" placeholder="请输入钱包地址" clearable/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="queryUserOrders">查询</el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <div class="item-box" v-for="(order,index) in orders" :key="index">
        <div class="order-title">
          <span>订单号：{{ order.orderNo }}</span>
          <el-button type="primary" @click="onDistributionClick(order.orderNo,order.amount)">分配收益</el-button>
        </div>
        <el-divider/>
        <div class="order-item">投资金额：{{ order.amount }}</div>
        <div class="order-item">投资收益：{{ order.income }}</div>
        <div class="order-item">已提收益：{{ order.extractedIncome }}</div>
        <div class="order-item">投资日期：{{ order.investTime }}</div>
        <div class="order-item">到期日期：{{ order.expireTime }}</div>
      </div>
    </div>
  </div>

  <el-dialog v-model="showInvestDialog" title="分配收益" width="600px">
    <el-form :model="form">
      <el-form-item label="收益率" :label-width="formLabelWidth">
        <el-input v-model="form.rate" autocomplete="off"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="showInvestDialog = false">取消</el-button>
        <el-button :loading-icon="Eleme" :loading="confirmLoading" type="primary"
                   @click="distributionInvestIncome(form.orderNo,form.amount,form.rate)">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>

</template>
<script setup>
import {onMounted, reactive, ref} from "vue";
import Web3 from "web3";
import {
  stakingContractABI,
  stakingContractAddress
} from "@/assets/chain/contractDetails";
import {DateTimeUtils} from "@/utils/DateTimeUtils";
import WalletUtils from "@/utils/WalletUtils";
import StringUtils from "@/utils/StringUtils";
import {ElMessage} from "element-plus";

const strUtils = ref(new StringUtils());
const walletAddress = ref(undefined)
const customerAddress = ref(undefined)
const showInvestDialog = ref(false);
const formLabelWidth = '120px'
const form = reactive({
  rate: 0,
  amount: 0,
  orderNo: undefined,
})
const confirmLoading = ref(false)
const onDistributionClick = (orderNo, amount) => {
  showInvestDialog.value = true;
  form.amount = amount;
  form.orderNo = orderNo;
  form.rate = 0;
}


let stakingContractInstance = undefined;
let web3 = undefined;
const initWeb3 = (async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    stakingContractInstance = new window.web3.eth.Contract(stakingContractABI, stakingContractAddress);
  } else {
    console.error("Please install MetaMask!")
  }
});

const getAddress = (async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    walletAddress.value = await walletUtils.getWalletAddress();
  }
});
const orders = ref([])
const queryUserOrders = (async () => {
  if (customerAddress.value) {
    totalIncome.value = 0;
    const result = await stakingContractInstance.methods.getUserOrders(customerAddress.value).call();
    orders.value = [];
    for (const orderNo of result) {
      await getOrderInfo(Number(orderNo));
    }
  } else {
    ElMessage.error('请输入钱包地址');
  }
});

const totalIncome = ref(0);

const getOrderInfo = (async (orderNo) => {
  const info = await stakingContractInstance.methods.orders(orderNo).call();
  const order = {
    orderNo: Number(info.orderNo),
    amount: Number(web3.utils.fromWei(info.amount, 'ether')),
    income: Number(web3.utils.fromWei(info.income, 'ether')),
    extractedIncome: Number(web3.utils.fromWei(info.extractedIncome, 'ether')),
    investTime: DateTimeUtils.formatTimestamp(Number(info.investTime)),
    expireTime: DateTimeUtils.formatTimestamp(Number(info.expireTime))
  }
  totalIncome.value += order.income - order.extractedIncome;
  orders.value.push(order)
})

const distributionInvestIncome = (async (orderNo, amount, rate) => {
  try {
    confirmLoading.value = true
    const investAmount = web3.utils.toWei(amount * rate / 100, 'ether');
    const orderNos = [orderNo];
    const amounts = [investAmount];
    await stakingContractInstance.methods.distributionInvestIncome(orderNos, amounts).send({from: walletAddress.value})
    ElMessage.success('提交成功')
    confirmLoading.value = false;
    showInvestDialog.value = false;
  } catch (error) {
    console.log(error)
    ElMessage.error('提交失败')
    confirmLoading.value = false;
  }
})

onMounted(async () => {
  await initWeb3();
  await getAddress();
})

</script>
<style scoped lang="less">

.form-inline .el-input {
  --el-input-width: 400px;
}

.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .nav {
    flex: 0 0 auto;
    width: 100%;

    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      background-color: white;
      padding: 5px;
      box-sizing: border-box;

      .wallet-box {
        padding: 5px 10px;
        box-sizing: border-box;
        border: 1px solid #42b983;
        border-radius: 5px;
        background: #6ef5b5;
      }

      span {
        font-size: 12px;
      }
    }
  }

  .main {
    flex: 1 0 0;
    overflow: auto;

    .search-box {
      width: 980px;
      margin-top: 20px;
    }

    .item-box {
      width: 980px;
      margin-top: 10px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 5px;
      box-sizing: border-box;

      .order-title {
        margin-top: 14px;
        width: 100%;
        box-sizing: border-box;
        text-align: start;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .order-item {
        margin: 10px 0;
        text-align: start;
        font-size: 14px;
      }

    }
  }

}

</style>
