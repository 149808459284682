// DateTimeFormatter.js

export class DateTimeUtils {
    /**
     * 获取当前时间的时间戳
     * @param {boolean} isMilliseconds - 是否返回十三位的毫秒级时间戳，默认为 false
     * @returns {number} 当前的时间戳（十位或十三位）
     */
    static getCurrentTimestamp(isMilliseconds = false) {
        if (isMilliseconds) {
            return Date.now();
        } else {
            return Math.floor(Date.now() / 1000);
        }
    }

    /**
     * 格式化时间戳
     * @param {number} timestamp - 时间戳（十位秒级或十三位毫秒级）
     * @param {string} format - 日期时间格式，默认为 "yyyy-MM-dd HH:mm:ss"
     * @returns {string} 格式化的日期时间字符串
     */
    static formatTimestamp(timestamp, format = "yyyy-MM-dd HH:mm:ss") {
        // 检测时间戳长度，转换为十位秒级时间戳
        if (timestamp.toString().length === 13) {
            timestamp = Math.floor(timestamp / 1000);
        }

        // 将十位时间戳转换为 JavaScript 的日期对象
        const date = new Date(timestamp * 1000);

        // 定义格式化日期的函数
        const zeroPad = (num, places) => String(num).padStart(places, '0');

        // 定义各个时间组件
        const components = {
            yyyy: date.getFullYear(),
            MM: zeroPad(date.getMonth() + 1, 2),  // getMonth 返回的月份是从 0 开始的
            dd: zeroPad(date.getDate(), 2),
            HH: zeroPad(date.getHours(), 2),
            mm: zeroPad(date.getMinutes(), 2),
            ss: zeroPad(date.getSeconds(), 2)
        };

        // 替换格式字符串中的年月日时分秒
        return format.replace(/yyyy|MM|dd|HH|mm|ss/g, match => components[match]);
    }
}
